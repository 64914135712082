html, body {
  overflow: hidden; 
  width: 100%;
  height: 100%;
  margin:0; 
  padding:0;
}

xr-button-overlay{
  z-index:11;
  position: absolute;
  right: 50px;
  bottom: 50px;
}
#babylonUnmuteIconBtn{
  z-index:11;
  position: absolute;
  top: 25%!important;
  left: 50px!important;
}
.babylonUnmuteIcon{
  z-index:11;
  position: absolute;
  top: 25%!important;
  left: 50px!important;
}

canvas {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

#renderCanvas {
  left:0; 
  top:0;
  width: 100%;
  height: 100%;
  touch-action: none;
  position: absolute;
  z-index:0;
}

#loading {
  display: block;
  width: 150px;
  height: 150px;
  margin:auto;
  position:absolute;
  top:0px;
  bottom:0px;
  left:0px;
  right:0px;
  border: 6px solid rgba(255, 0, 0, 0.397);
  border-radius: 50%;
  z-index: 100;
  border-top-color: rgb(255, 2, 2);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}

.noselect {
  z-index:1;
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
		-webkit-tap-highlight-color: transparent;
    touch-action: none;
    pointer-events: none;
}

.front {
  z-index:1111!important;
  pointer-events: all!important;
}
.btn:disabled,
.btn[disabled]{
  border: 1px solid #ff0000;
  background-color: #cccccc54;
  color: #66666625;
  opacity: .20;
}

.btn:focus,.btn:active,
.btn[focus],.btn[active]{
  outline: none !important;
  box-shadow: none;
}